import { Box, Popover, Stack, Typography } from "@mui/material";
import type { Dayjs } from "dayjs";
import type { ReactNode, RefObject } from "react";
import { useId, useRef, useState } from "react";
import { useToggle } from "usehooks-ts";

import { Button } from "./button";
import { DateRangeCalendar } from "./date-range-calendar";

type DateRangeSelectPopoverProps = {
  value?: [Dayjs?, Dayjs?];
  onChange?: (value: [Dayjs, Dayjs]) => void;
  onCancel?: () => void;
  onReset?: () => void;
  renderControl: <T>(props: {
    open: boolean;
    toggle: () => void;
    anchorRef: RefObject<T>;
  }) => ReactNode;
};
export function DateRangeSelectPopover(props: DateRangeSelectPopoverProps) {
  const { renderControl, value } = props;
  const [open, toggle] = useToggle();
  const id = useId();
  const anchorRef = useRef(null);

  function handleCancel() {
    toggle();
    props.onCancel?.();
  }

  function handleChange(value: [Dayjs, Dayjs]) {
    toggle();
    props.onChange?.(value);
  }

  function handleReset() {
    toggle();
    props.onReset?.();
  }

  return (
    <>
      {renderControl({ open, toggle, anchorRef })}
      <Popover
        id={`${id}-popover`}
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 2,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "neutral.2",
              boxShadow: (theme) => theme.palette.shadows.light[500],
              px: 1,
            },
          },
        }}
        sx={{
          mt: 1,
          maxHeight: "90vh",
        }}
      >
        <DateRangeSelectForm
          defaultValue={value}
          onChange={handleChange}
          onCancel={handleCancel}
          onReset={handleReset}
        />
      </Popover>
    </>
  );
}

// Separate the Calendar from the popover to reset internal state when the popover is closed
type DateRangeSelectFormProps = {
  defaultValue?: [Dayjs?, Dayjs?];
  onChange?: (value: [Dayjs, Dayjs]) => void;
  onCancel?: () => void;
  onReset?: () => void;
};
export function DateRangeSelectForm(props: DateRangeSelectFormProps) {
  const [value, setValue] = useState<[Dayjs?, Dayjs?]>(
    props.defaultValue || [undefined, undefined],
  );

  function handleCancel() {
    props.onCancel?.();
  }

  function handleReset() {
    props.onReset?.();
  }

  function handleSubmit() {
    if (!value[0] || !value[1]) return;
    props.onChange?.(value as [Dayjs, Dayjs]);
  }

  function timeLabel() {
    let label = "";
    if (value[0]) label += value[0].format("MMM DD");
    if (value[1]) label += ` - ${value[1].format("MMM DD")}`;
    return label || "Select a time range below";
  }

  return (
    <div>
      <Stack
        spacing={1}
        sx={{ px: 3, py: 1.5, borderBottom: "1px solid", borderBottomColor: "neutral.2" }}
      >
        <Typography variant="sh4" color="neutral.5">
          Selected time range
        </Typography>
        <Typography variant="sh5" color="neutral.7">
          {timeLabel()}
        </Typography>
      </Stack>
      <Box sx={{ px: 2 }}>
        <DateRangeCalendar defaultValue={value} onChange={setValue} />
      </Box>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        sx={{ pb: 1.5, pt: 1, borderTop: "1px solid", borderTopColor: "neutral.2" }}
      >
        <Button variant="text" onClick={handleReset} sx={{ color: "neutral.5" }}>
          Reset all
        </Button>
        <Stack direction="row" spacing={1}>
          <Button variant="subtle" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!value[0] || !value[1]}>
            Set Date
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
