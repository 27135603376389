import { Box } from "@mui/material";
import type { DateCalendarProps, PickersDayProps } from "@mui/x-date-pickers";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import type { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

type DateRangeCalendarProps = Omit<
  DateCalendarProps<Dayjs>,
  "defaultValue" | "value" | "onChange"
> & {
  defaultValue?: [Dayjs?, Dayjs?];
  value?: [Dayjs?, Dayjs?];
  onChange?: (value: [Dayjs?, Dayjs?]) => void;
};

export function DateRangeCalendar({
  onChange,
  defaultValue,
  value,
  sx,
  ...props
}: DateRangeCalendarProps) {
  const [dates, setDates] = useState<[Dayjs?, Dayjs?]>(defaultValue || [undefined, undefined]);
  const [selectingIndex, setSelectingIndex] = useState<0 | 1>(0);

  function handleDatesChange(newDates: [Dayjs?, Dayjs?]) {
    onChange?.(newDates);
    if (!value) setDates(newDates);
  }

  function handleSelectDate(selected: Dayjs | null) {
    if (!selected) return;
    if (selectingIndex === 0) {
      if (dates[1] && selected.isAfter(dates[1], "day")) {
        handleDatesChange([selected, undefined]);
      } else {
        handleDatesChange([selected, dates[1]]);
      }
      setSelectingIndex(1);
    } else {
      if (selected.isBefore(dates[0], "day")) {
        handleDatesChange([selected, undefined]);
        setSelectingIndex(1);
      } else {
        handleDatesChange([dates[0], selected]);
        setSelectingIndex(0);
      }
    }
  }

  useEffect(() => {
    if (value) {
      setDates(value);
      setSelectingIndex(value[0] && value[1] ? 0 : 1);
    }
  }, [value]);

  return (
    <DateCalendar
      onChange={handleSelectDate}
      slots={{ day: Day }}
      slotProps={{ day: { selectedDates: dates } as any }}
      sx={{
        height: "fit-content",
        maxHeight: 400,
        "& .MuiPickersYear-root .Mui-selected": {
          "&:hover, &:focus, &:focus-visible": {
            bgcolor: "primary.4",
          },
        },
        "& .MuiPickersCalendarHeader-root": {
          paddingLeft: 1,
        },
        "& .MuiDayCalendar-weekContainer": {
          margin: 0,
        },
        "& .MuiDayCalendar-weekDayLabel, & .MuiDayCalendar-weekNumberLabel": {
          width: 48,
          height: 48,
          margin: 0,
        },
        "& .MuiPickersDay-hiddenDaySpacingFiller, & .MuiPickersDay-root": {
          width: 40,
          height: 40,
        },
        "& .MuiDayCalendar-loadingContainer, & .MuiDayCalendar-slideTransition": {
          minHeight: 320,
        },
        ...sx,
      }}
      {...props}
    />
  );
}

function Day(props: PickersDayProps<Dayjs> & { selectedDates?: [Dayjs?, Dayjs?] }) {
  const { day, selectedDates, ...other } = props;
  const [startDate, endDate] = selectedDates || [];
  const isFirstSelected = startDate ? day.isSame(startDate, "day") : false;
  const isLastSelected = endDate ? day.isSame(endDate, "day") : false;
  const isSelected = isFirstSelected || isLastSelected;
  const isBetween = startDate && endDate ? day.isBetween(startDate, endDate, "day", "()") : false;
  return (
    <Box
      sx={{
        position: "relative",
        width: 48,
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...(isBetween && {
            bgcolor: "primary.1",
          }),
          ...(isFirstSelected &&
            endDate && {
              bgcolor: "primary.1",
              borderTopLeftRadius: 9999,
              borderBottomLeftRadius: 9999,
              left: 8,
            }),
          ...(isLastSelected &&
            startDate && {
              bgcolor: "primary.1",
              borderTopRightRadius: 9999,
              borderBottomRightRadius: 9999,
              right: 8,
            }),
        }}
      />
      <PickersDay
        {...other}
        day={day}
        disableMargin
        selected={isSelected}
        sx={{
          typography: "b5",
          color: "neutral.7",
          "&.MuiPickersDay-today": {
            borderColor: "primary.4",
            color: isBetween ? "neutral.7" : "primary.4",
          },
          ...(isSelected && {
            bgcolor: "primary.4",
            color: "white",
          }),
          "&:hover, &:focus, &:focus-visible": {
            bgcolor: "primary.2",
            color: "neutral.7",
          },
          "&.Mui-selected, &:focus.Mui-selected, &:hover.Mui-selected": {
            bgcolor: "primary.4",
            color: "white",
          },
        }}
      />
    </Box>
  );
}
